import React, { useState } from "react";
import '../css/doctors.scss';
import doctors from "../data/doctors.json";

const Doctor = ({ data, language }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(doctors);

  // Search handler function
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.length >= 2) {
      const filtered = doctors.filter((item) => {
        // Check both mn and en data for matches
        const mnData = item.mn;
        const enData = item.en;

        return (
          (mnData.name && mnData.name.toLowerCase().includes(term)) ||
          (mnData.clinic && mnData.clinic.toLowerCase().includes(term)) ||
          (enData.name && enData.name.toLowerCase().includes(term)) ||
          (enData.clinic && enData.clinic.toLowerCase().includes(term))
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(doctors);
    }
  };

  return (
    <div className='doctors-container container11 d-flex justify-content-center' id='doctors'>
      <div className='fs-3 fw-bold doctors-title'>{data.title}</div>
      <input
        type='text'
        placeholder={data.placeholder}
        value={searchTerm}
        onChange={handleSearch}
        className='mt-3 mb-3 p-2 col-md-9 col-9 mb-5'
      />

      <div className='col-11 col-sm-9 row'>
        {filteredData.map((item) => (
          <div key={"doctors" + item.id} className='doctor-item col-6 col-sm-6 col-md-4 col-lg-3 d-flex flex-column justify-content-start align-items-center'>
            <div
              className='rounded-circle d-flex justify-content-center align-items-center'
              style={{ objectFit: "cover", width: 150, height: 150 }}>
              <img
                src={item.image === "" ? "/images/team/member.png" : item.image}
                alt={item[language].name}
              />
            </div>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center mt-3'>
              <span className='text-dark text-center fw-bold fs-5'>
                {item[language].name}
              </span>
              <span className='text-muted text-center'>
                {item[language].clinic}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Doctor;
