import React from 'react';
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = ({ data, language}) => {
  return (
    <>
      <Header
        title={language ? data.English.Title : data.Mongolian.Title}
        data={language ? data.English.Header : data.Mongolian.Header}
      />
      <Outlet />
    </>
  )
};

export default Layout;
