import AOS from "aos";
import "aos/dist/aos.css";
import React, { useContext, useEffect } from "react";
import { FaBook, FaClinicMedical, FaHospitalUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ThemeContext } from "../App";
import "../css/services.scss";
const Services = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className='services w-100 h-75 d-flex flex-column align-items-center'
      id='services'>
      <h1 className='services-title text-center'>{data.title}</h1>
      <div
        className='line'
        style={{
          backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
        }}
      />
      <div className='services-container w-100 h-100 row d-flex justify-content-center align-items-center'>
        <div
          className='service-card col-lg-3 col-sm-6  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-right'>
          <div className='mini-service d-flex align-items-center justify-content-center'>
            <FaClinicMedical color={theme[1]} size={40} />
            <div className='text-center h-50'>
              <Link to={data.links[0]}>{data.services[0]}</Link>
            </div>
          </div>
        </div>
        <div
          className='service-card col-lg-3 col-sm-6  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-up'>
          <div className='mini-service d-flex align-items-center justify-content-center'>
            <FaHospitalUser color={theme[1]} size={40} />
            <div className='text-center h-50'>
              <Link to={data.links[1]}>{data.services[1]}</Link>
            </div>
          </div>
        </div>
        <div
          className='service-card col-lg-3 col-sm-6  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-left'>
          <div className='mini-service d-flex align-items-center justify-content-center'>
            <FaBook color={theme[1]} size={40} />
            <div className='text-center h-50'>
              <Link to={data.links[2]}>{data.services[2]}</Link>
            </div>
          </div>
        </div>
        {/* <div
          className='service-card col-lg-2 col-sm-6  d-flex align-items-center justify-content-center mt-3'
          data-aos='fade-up'>
          <div className='mini-service d-flex align-items-center justify-content-center'>
            <GiVideoConference color={theme[1]} size={60} />
            <div className='text-center h-50'>{data.services[3]}</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Services;
