import React, { useEffect, useMemo, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useParams } from "react-router-dom";
import "../css/pdf-reader.scss";
import journalsData from "../data/journals.json";
class JournalModel {
  id
  title
  url
  showPageUntil
  year
  tags
}

const JournalReader= ({ data }) => {
  const pageLimitDefault = 3;
  const params = useParams();
  const [journal, setJournal] = useState(new JournalModel());
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const options = useMemo(() => ({
    cMapUrl: '/cmaps/',
    useSystemFonts: true,
    renderAnnotationLayer: false,
    renderTextLayer: false,
  }), []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages((journal?.showPageUntil || pageLimitDefault) > numPages ? numPages : (journal?.showPageUntil || pageLimitDefault));
  }

  const prev =() => {
    if (1 < pageNumber) {
      setPageNumber(pageNumber - 1)
    }
  }

  const next = () => {
    if (pageNumber < (journal?.showPageUntil || pageLimitDefault)) {
      setPageNumber(pageNumber + 1)
    }
  }

  useEffect(() => {
    if (params.id && !journal.url) {
      journalsData.filter(journal => journal.id === Number(params.id)).map(journal => {
        setJournal(journal)
        console.log(journal)
      });
    }
  }, [params.id]);

  return (
    <div className="pdf-reader">

      <div className="journal-title">{journal.title}</div>
      <div className="year">{journal?.year} [{journal?.tags?.join(",")}]</div>

      <div className="controls">
        <span className="arrow">
          <FaArrowLeft size={20} onClick={() => prev()}/>
        </span>
        <span className="page-number">{data.page} {pageNumber} / {numPages}</span>
        <span className="arrow">
          <FaArrowRight size={20} onClick={() => next()}/>
        </span>
      </div>

      { pageNumber === (journal?.showPageUntil || pageLimitDefault) ?
      <div className="payment">{data.payment}</div> : null}
      
      <Document options={options} file={journal?.url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
}

export default JournalReader;