import React, { useContext } from "react";
import { ThemeContext } from "../App";
import "../css/team.scss";

const Team = ({ data }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="container11"
      id="team"
      style={{
        backgroundImage: `linear-gradient(${theme[0]}, ${theme[1]})`,
      }}
    >
      <h1 className="text-light team-title">{data.title}</h1>
      <div className="line" style={{ backgroundColor: "white" }} />
      <div className="teamContainer row w-100">
        {data.profiles.map((team, index) => (
          <div
            key={index}
            className="row w-100 justify-content-center border-bottom p-3"
          >
            {team.map((member, idx) => (
              <div key={idx} className="col-lg-3 col-md-4 col-6">
                <div className="memberInfo">
                  <img
                    src={member.profileImage}
                    className="profilePng"
                    alt="profile"
                  />
                  <p className="personInfo personName text-light">{member.name}</p>
                  <p className="personInfo text-light">{member.title}</p>
                  <p className="personInfo text-light">
                    {member.comprehensive}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
