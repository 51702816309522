import React, { useState } from "react";
import { MdConstruction } from "react-icons/md";
import { Link } from "react-router-dom";
import "../css/sign.scss";

const SignIn = ({ data }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("SignIn Data:", formData);
  };

  return (
    <div className="sign-container">
      <div className="sign-container-card">
        <h2>{data.signin}</h2>

        <div className="w-100 d-flex justify-content-center">
          <h3>Under Construction</h3>
        </div>
        <div className="w-100 d-flex justify-content-center" >
          <MdConstruction size={50} />
        </div>

        <form onSubmit={handleSubmit}>
          <div>
            <label className="sign-label">{data.email}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div>
            <label className="sign-label">{data.password}</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <button type="submit" className="sign-button">
            {data.signin}
          </button>
          <Link to={"/signup"} className="sign-text">
            {data.textOne}{" "}
            <span className="sign-link">{data.textTwo}</span>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
