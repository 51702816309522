import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import "../css/events.scss";

const Training = ({ data }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='events-container'>
      <h1>{data.title}</h1>
      
      <section className="data-container w-100">
        <hr/>

        { data?.list?.length === 0 ?
        <div className='d-flex justify-content-center w-100'>
            {data.noDataFound}
        </div>
        :
        data.list.map((event) => {
          return (
            <div className="width-100" key={event.name}>
              <>
              <hr/>
                <div key={event.name} className='p-5 d-flex justify-content-center'>
                  <h3>{event.name}</h3>
                </div>
                {
                event.images.map((image, idx) => {
                  return (
                    <div className='row d-flex justify-content-center' key={'row-' + idx}>
                      <div className='col-md-8 col-lg-6'>
                        <img className="width-100" title={event.name} src={image} alt={event.name || ''}/>
                      </div>
                    </div>
                  );
                })
                }
              </>
            </div>
          );
        })}
      </section>
    </div>
  );
};
export default Training;