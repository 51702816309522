import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import "../css/events.scss";

const Conference = ({ data }) => {

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='events-container'>
      <h1>{data.title}</h1>
      
      <section className="data-container w-100">
        <hr/>

        { data?.list?.length === 0 ?
        <div className='d-flex justify-content-center w-100'>
            {data.noDataFound}
        </div>
        :
        data.list.map((event) => {
          return (
            <div className="width-100" key={event.name}>
              <>
              <hr/>
                <div key={event.name} className='p-5 d-flex justify-content-center'>
                  <h3>{event.name}</h3>
                </div>
              </>
            </div>
          );
        })}
      </section>
    </div>
  );
};
export default Conference;