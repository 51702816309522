import AOS from "aos";
import "aos/dist/aos.css";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../App";
import "../css/home.css";

const Home = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { theme } = useContext(ThemeContext);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="home-body" id="home">
      <div
        className="home-inner"
        style={{
          backgroundImage: `linear-gradient(${theme[1]}, ${theme[0]})`,
        }}
      >
        {width <= 768 ? (
          <>
            <Link className="home-real-button" to={data.links[0]}>
              {data.menu[0]}
            </Link>
            <Link className="home-real-button" to={data.links[1]}>
              {data.menu[1]}
            </Link>
            <Link className="home-real-button" to={data.links[2]}>
              {data.menu[2]}
            </Link>
          </>
        ) : (
          <></>
        )}
        <div className="home-img-cntnr">
          {width > 768 ? (
            <>
              <div className="home-button home-button1">
                <div className="home-circle">
                  <Link className="home-text" to={data.links[0]}>
                    {data.menu[0]}
                  </Link>
                </div>
              </div>
              <div className="home-button home-button2">
                <div className="home-circle">
                  <Link className="home-text" to={data.links[1]}>
                    {data.menu[1]}
                  </Link>
                </div>
              </div>
              <div className="home-button home-button3">
                <div className="home-circle">
                  <Link className="home-text" to={data.links[2]}>
                    {data.menu[2]}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <img src={data.image} className="mainImg" alt="profile" />

          {width > 768 ? (
            <>
              <div className="home-button home-button4">
                <div className="home-circle home-circle-left">
                  <Link className="home-text home-text-left" to={data.links[3]}>
                    {data.menu[3]}
                  </Link>
                </div>
              </div>
              <div className="home-button home-button5">
                <div className="home-circle home-circle-left">
                  <Link className="home-text home-text-left" to={data.links[4]}>
                    {data.menu[4]}
                  </Link>
                </div>
              </div>
              <div className="home-button home-button6">
                <div className="home-circle home-circle-left">
                  <Link className="home-text home-text-left" to={data.links[5]}>
                    {data.menu[5]}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {width <= 768 ? (
          <>
            <Link className="home-real-button" to={data.links[3]}>
              {data.menu[3]}
            </Link>
            <Link className="home-real-button" to={data.links[4]}>
              {data.menu[4]}
            </Link>
            <Link className="home-real-button" to={data.links[5]}>
              {data.menu[5]}
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Home;
