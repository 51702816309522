import React, { useEffect, useState } from "react";
import { FaRegFilePdf } from "react-icons/fa6";
import "../css/journal.scss";
import journalsData from "../data/journals.json";

const JournalList = ({ data }) => {
  const [filteredData, setFilteredData] = useState(journalsData);
  const [searchText, setSearchText] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  // Get unique years from data
  const years = Array.from(new Set(journalsData.map((item) => item.year))).sort();

  // Get unique tags from data
  const tags = Array.from(new Set(journalsData.flatMap((item) => item.tags)));

  // Handle tag selection
  const handleTagChange = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  // Filter data based on all criteria
  useEffect(() => {
    let result = journalsData;

    // Text search filter
    if (searchText) {
      result = result.filter(
        (item) =>
          item.title.toLowerCase().includes(searchText.toLowerCase()) ||
          item.authors.some((author) =>
            author.toLowerCase().includes(searchText.toLowerCase())
          )
      );
    }

    // Year filter
    if (selectedYear) {
      result = result.filter((item) => item.year === parseInt(selectedYear));
    }

    // Tags filter
    if (selectedTags.length > 0) {
      result = result.filter((item) =>
        selectedTags.every((tag) => item.tags.includes(tag))
      );
    }

    setFilteredData(result);
  }, [searchText, selectedYear, selectedTags]);

  return (
    <div className='journalContainer d-flex flex-column align-items-center '>
      <div className='journal-title fs-3 fw-bold'>{data.title}</div>
      <div className='filters col-11 col-sm-9'>
        <div className='inputContainer d-flex justify-content-between align-items-center'>
          {/* Search input */}
          <input
            type='text'
            placeholder={data.placeholder}
            className="journal-search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          {/* Year dropdown */}
          <select
            value={selectedYear}
            className='w-25'
            onChange={(e) => setSelectedYear(e.target.value)}>
            <option value=''>{data.allYears}</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {/* Tags checkboxes */}
        <div className='tagsFilter'>
          <label>{data.keyword}:</label>
          {tags.map((tag) => (
            <label key={tag}>
              <input
                type='checkbox'
                checked={selectedTags.includes(tag)}
                onChange={() => handleTagChange(tag)}
              />
              &nbsp;
              {tag}
            </label>
          ))}
        </div>
      </div>

      {/* Display filtered results */}
      <div className='journalsList col-11 col-sm-9'>
        {filteredData.map((journal) => (
          <div key={journal.id} className='journalItem'>
            <h3>{journal.title}</h3>
            <div className='d-flex flex-column'>
              <span className='text-dark'>{data.author}: {journal.authors.join(", ")}</span>
              <span className='text-dark'>{data.year}: {journal.year}</span>
              <span className='text-dark'>{data.keyword}: [{journal.tags.join(", ")}]</span>
            </div>
            <a
              href={"/journal/" + journal.id}
              target='_blank'
              rel='noopener noreferrer'
              className='d-flex justify-content-end'>
              <FaRegFilePdf size={20} />
              &nbsp;
              {data.open}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
export default JournalList;
