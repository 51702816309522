import React from "react";
import "../css/donation.scss";

const Donation = ({data}) => {
  return (
    <div className='donation-container'>
      <h1 className='title'>{data.title}</h1>
      <div className='container row w-100'>
        <div className="info">{data.paragraph}</div>
        {/* <p>{data.paragraphTwo}</p> */}
        <div className="bank-details">
          <p>{data.bank}</p>
          <p>{data.account}</p>
          <p>{data.accountName}</p>
        </div>
      </div>
    </div>
  );
};

export default Donation;
