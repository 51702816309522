// @ts-nocheck
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AnnualMeetingArchive from "./component/AnnualMeetingArchive";
import AnnualMeetings from "./component/AnnualMeetings";
import Clinic from "./component/Clinic";
import Conference from "./component/Conference";
import Doctor from "./component/Doctor";
import Donation from "./component/Donation";
import Events from "./component/Events";
import JournalList from "./component/JournalList";
import JournalReader from "./component/JournalReader";
import Layout from "./component/Layout";
import { Main } from "./component/Main";
import NotFound from "./component/NotFound";
import SignIn from "./component/SignIn";
import SignUp from "./component/SignUp";
import Training from "./component/Traning";
import data from "./data/data.json";


export const ThemeContext = createContext(null);

function App() {
  window.$ = window.jQuery = require("jquery");
  const [language, setLanguage] = useState(false);
  const [theme, setTheme] = useState([
    data.Theme.ThemeColor.colorOne,
    data.Theme.ThemeColor.colorTwo,
  ]);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        localStorage.setItem("msid_app_lang", data.country_code)
        if (data.country_code === "MN") {
          setLanguage(false);
        } else {
          setLanguage(true);
        }
      } catch (error) {
        console.error("Error fetching the country code:", error);
      }
    };

    let msid_app_lang = localStorage.getItem("msid_app_lang")
    if (msid_app_lang) {
      if (msid_app_lang === "MN") {
        setLanguage(false);
      } else {
        setLanguage(true);
      }
    } else {
      fetchCountryCode();
    }
  }, []);
  return (
    <ThemeContext.Provider value={{ language, setLanguage, theme, setTheme }}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout data={data} language={language} />}>
            <Route
              index
              path='/'
              element={<Main data={data} language={language} />}
            />
            <Route
              path='/donation'
              element={
                <Donation
                  data={
                    language ? data.English.Donation : data.Mongolian.Donation
                  }
                />
              }
            />
            <Route
              path='/events'
              element={
                <Events
                  data={language ? data.English.Events : data.Mongolian.Events}
                />
              }
            />
            <Route
              path="/signin"
              element={
                <SignIn
                  data={language ? data.English.SignIn : data.Mongolian.SignIn}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <SignUp
                  data={language ? data.English.SignUp : data.Mongolian.SignUp}
                />
              }
            />
            <Route
              path='/training'
              element={
                <Training
                  data={
                    language ? data.English.Training : data.Mongolian.Training
                  }
                />
              }
            />
            <Route
              path='/conference'
              element={
                <Conference
                  data={
                    language
                      ? data.English.Conference
                      : data.Mongolian.Conference
                  }
                />
              }
            />
            <Route
              path='/annual-meetings'
              element={
                <AnnualMeetings
                  data={
                    language
                      ? data.English.AnnualMeetings
                      : data.Mongolian.AnnualMeetings
                  }
                />
              }
            />
            <Route
              path='/archive'
              element={
                <AnnualMeetingArchive
                  data={
                    language
                      ? data.English.MeetingArchive
                      : data.Mongolian.MeetingArchive
                  }
                />
              }
            />
            <Route
              path='clinics'
              element={
                <Clinic data={language
                  ? data.English.Clinics
                  : data.Mongolian.Clinics} language={language ? "en" : "mn"} />
              }
            />
            <Route
              path='doctors'
              element={
                <Doctor data={language
                  ? data.English.Doctors
                  : data.Mongolian.Doctors} language={language ? "en" : "mn"} />
              }
            />
            <Route
              path='journals'
              element={
                <JournalList
                  data={language
                    ? data.English.Journals
                    : data.Mongolian.Journals}
                  language={language ? "en" : "mn"}
                />
              }
            />
            <Route
              path='journal/:id'
              element={
                <JournalReader
                  data={language
                    ? data.English.Journals
                    : data.Mongolian.Journals}
                  language={language ? "en" : "mn"}
                />
              }
            />
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeContext.Provider>
  );
}
export default App;
