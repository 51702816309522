import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import "../css/gallery.scss";


const Gallery = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className='container1' id='gallery'>
      <div className="gallery-title">{data.title}</div>
      <p>{data.detailedTitle}</p>
      
      <div className='image-container'>
        <div className="row">
          <div className="column">
            {data.images.slice(0, Math.floor(data.images.length / 2)).map((el, idx) => {
              return (
                <img src={el}/>
                // <Image
                //   title={data.titles?.length > idx ? data.titles[idx] : ""}
                //   image={el}
                // />
              );
            })}
          </div>
          <div className="column">
            {data.images.slice(Math.floor(data.images.length / 2), data.images.length - 1).map((el, idx) => {
              return (
                <img src={el}/>
                // <Image
                //   title={data.titles?.length > idx ? data.titles[idx] : ""}
                //   image={el}
                // />
              );
            })}
          </div>
        </div>
      </div>

      {/* <div className='imageContainer row p-5'>
        {data.images.map((el, idx) => {
          return (
            <Image
              title={data.titles?.length > idx ? data.titles[idx] : ""}
              image={el}
            />
          );
        })}
      </div> */}
    </div>
  );
};
export default Gallery;
