import AOS from "aos";
import "aos/dist/aos.css";
import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { CgEditBlackPoint } from "react-icons/cg";
import { Link } from "react-router-dom";
import { ThemeContext } from "../App";
import "../css/about.scss";

const About = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className='about-body d-flex flex-column align-items-center'
      id='about'>
      <div className='about-title text-center'>{data.title}</div>
      <div
        className='line'
        style={{
          backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
        }}
      />
      <div className='m-3'>{data.detailedTitle}</div>
      <div className='row pt-2 width-100'>
        <div
          className='about-img-container col-lg-6 col-md-12 p-5 p-lg-0'
          data-aos='fade-right'>
          <img alt='img' className='about-img' src={data.image} />
        </div>
        <div className='about-text col-lg-6 col-md-12 p-5' data-aos='fade-left'>
          <div data-aos='fade-left' className='m-3'>
            {data.introduction}
          </div>
          <div className='mini-title m-3 font-weight-bold'>{data.purpose}</div>
          <div data-aos='fade-left' className='mx-3 my-4 '>
            {data.textOne}
          </div>
          <div data-aos='fade-left' className='m-3'>
            {data.textTwo}
          </div>
        </div>
      </div>
      <div className='about-mini-title mt-5 text-center'>{data.titletwo}</div>
      <div
        className='line'
        style={{
          backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
        }}
      />
      <div className='row pt-2 width-100'>
        <div className='about-text about-links col-lg-3 col-md-12 p-3' data-aos='fade-left'>
          <div
            data-aos='fade-right'
            className='about-text-one mx-3 my-4'>
            <CgEditBlackPoint size={25} color="grey" />
            <Link style={{ "color": theme[1] }} to={data.links[0]}>{data.paragraphOne}</Link>
          </div>
          <div data-aos='fade-right' className='about-text-one m-3'>
            <CgEditBlackPoint size={25} color="grey" />
            <Link style={{ "color": theme[1] }} to={data.links[1]}>{data.paragraphTwo}</Link>
          </div>
        </div>
        <div className='col-lg-6 col-md-12 p-3 p-lg-0' data-aos='fade-up'>
          <div data-aos='fade-down'>
            {data.servicesTextOne}
          </div>
          <div data-aos='fade-down'>
            {data.servicesTextTwo}
          </div>
          <div data-aos='fade-down'>
            {data.servicesTextThree}
          </div>
        </div>
        {/* <div
          className='about-img-container col-lg-4 col-md-12 p-5 p-lg-0'
          data-aos='fade-up'> */}
          {/* <img alt='img' className='about-img' src={data.paragraphImage} /> */}
        {/* </div> */}
        <div className='about-text about-links col-lg-3 col-md-12 p-3' data-aos='fade-left'>
          <div
            data-aos='fade-left'
            className='about-text-one mx-3 my-4'>
            <CgEditBlackPoint size={25} color={"grey"} />
            &nbsp;
            <Link style={{ "color": theme[1] }} to={data.links[2]}>{data.paragraphThree}</Link>
          </div>
          <div data-aos='fade-left' className='about-text-one m-3'>
            <CgEditBlackPoint size={25} color="grey" />
            &nbsp;
            <Link style={{ "color": theme[1] }} to={data.links[3]}>{data.paragraphFour}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    detailedTitle: PropTypes.string,
    image: PropTypes.string,
    introduction: PropTypes.string,
    purpose: PropTypes.string,
    textOne: PropTypes.string,
    textTwo: PropTypes.string,
    titletwo: PropTypes.string,
    paragraphOne: PropTypes.string,
    paragraphTwo: PropTypes.string,
    servicesTextOne: PropTypes.string,
    servicesTextTwo: PropTypes.string,
    servicesTextThree: PropTypes.string,
    paragraphThree: PropTypes.string,
    paragraphFour: PropTypes.string,
  }).isRequired,
};
export default About;
