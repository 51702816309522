import "aos/dist/aos.css";
import React from 'react';
import { MdConstruction } from "react-icons/md";
import { Link } from "react-router-dom";

const NotFound = () => {

  return (
    <section>
      <div className="w-100 d-flex justify-content-center" style={{ "paddingTop": "150px" }}>
        <h3>Under Construction</h3>
      </div>
      <div className="w-100 d-flex justify-content-center" >
        <MdConstruction size={50} />
      </div>
      <div className="w-100 d-flex justify-content-center" >
        <br/>
        <br/>
        <br/>
        <Link className="button" to="/">Home page</Link>
      </div>
    </section>
  );
};
export default NotFound;
